import axios from "axios";
import { targetStatus } from "./browser";
import { trackGa } from "../tracking/googleAnalytics";
import { TEXT_ADDRESS_IS_INVALID, TEXT_ENTER_YOUR_EMAIL } from "./constants";

export const checkParamsPrefix = (link) => (link.includes("?") ? "&" : "?");

export const concatUrlWithParams = (url, params) => {
  let urlWithParams = url;
  if (params) {
    Object.keys(params).forEach((key) => {
      urlWithParams += checkParamsPrefix(urlWithParams);
      urlWithParams += `${key}=${encodeURIComponent(params[key])}`;
    });
  }
  return urlWithParams;
};

export const transformKeywordsStringToUrlFormat = (keywords) =>
  keywords.replace(/ ,|, |,| /g, "+");

export const log = (message, title, isWarn, isCriticalError) => {
  let color = "rgba(18, 154, 95, 0.8)";
  if (isWarn) color = "rgba(241, 171, 39, 0.8)";
  if (isCriticalError) color = "rgba(225, 5, 5, 0.6)";
  const style = [
    "padding: 2px 4px;",
    `background-color: ${color};`,
    "border: 1px solid transparent;",
    "border-radius: 4px;",
    "font-weight: 700;",
    "color: #F7F7F7;",
    "line-height: normal;",
  ].join("");

  // if (process.env.LOGS === "true" || localStorage.getItem("logs")) {
  if (process.env.LOGS === "true") {
    console.log(`%c${title || "DEV LOG:"}`, style);
    !!message && console.log(message);
  }
};

export const checkValue = (key, value, isRequired = true, language = "en") => {
  if (isRequired && key !== "email") {
    if (value.length === 0) {
      return " ";
    }
  }
  switch (key) {
    case "asin":
      return (isRequired && value.length === 10) ||
        (!isRequired && (value.length === 0 || value.length === 10))
        ? ""
        : "ASIN length should be 10";
    case "price": {
      const regexp = new RegExp(/^\d*\.?\d*$/);
      return (value.length && regexp.test(value)) ||
        (!isRequired && value.length === 0)
        ? ``
        : `${key} should be numeric`;
    }
    case "quantity": {
      const regexp = new RegExp(/^\d+$/);
      return (isRequired && value.length && regexp.test(value)) ||
        (!isRequired && (value.length === 0 || regexp.test(value)))
        ? ``
        : `${key} should be positive natural digit`;
    }
    case "brand":
      return value.toString().length ? "" : `Invalid input parameters - ${key}`;
    case "keywords":
      return (isRequired && value.length) || !isRequired
        ? ""
        : `Invalid input parameters - ${key}`;
    case "email": {
      const isValid = checkEmail(value);
      const isEmpty = !value.length;

      const textEnterYourEmail =
        TEXT_ENTER_YOUR_EMAIL[language] || TEXT_ENTER_YOUR_EMAIL.en;

      const textAddressIsInvalid =
        TEXT_ADDRESS_IS_INVALID[language] || TEXT_ADDRESS_IS_INVALID.en;

      return isEmpty ? textEnterYourEmail : isValid ? "" : textAddressIsInvalid;
    }
    case "decimal": {
      return typeof value === "number" && value > 0;
    }
  }
};

export const checkEmail = (value) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const getLocation = () => {
  return axios.get(`${process.env.AUTH_HOST}/v1/auth/location`);
};

export const sendEventsCollectEmail = (email, type, isOperation, software) => {
  const errorEmail = localStorage.getItem("errorEmail");
  const body = {
    [isOperation ? "operation" : "type"]: type,
    email: errorEmail || email,
    ...(isOperation && !!software && { software }),
  };
  return fetch(process.env.API_HOST + "/events/track", {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const checkLicense = () => {
  return axios.get(
    `${process.env.AUTH_HOST}/v1/licences/software?state=ACTIVE`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
};

export const handleCheckLicenseForm = (setLicense, setIsOpacity) => {
  if (isAuthorizedUser()) {
    checkLicense()
      .then((res) => {
        if (res.status === 200) {
          const filter = res.data.filter((e) => {
            if (e.software === "WEB" && !e.trial) {
              return e;
            }
          });
          setLicense(filter.length > 0);
        }
      })
      .catch((e) => {
        log(e);
      })
      .finally(() => {
        setIsOpacity(false);
      });
  } else {
    setIsOpacity(false);
  }
};
export const handleCheckLicenseWebinars = (updatePlayerState) => {
  if (isAuthorizedUser()) {
    checkLicense().then((res) => {
      if (res.status === 200) {
        const filter = res.data.filter((e) => {
          if (
            (e.software === "WEB" ||
              e.software === "SCOUT_EXT_PRO" ||
              e.software === "SCOUT_EXT_PRO_NEW") &&
            !e.trial
          ) {
            console.log("software : ", e.software);
            return e;
          }
        });
        updatePlayerState("isLicense", filter.length > 0);
      }
    });
    // .finally(() => {
    //   setIsOpacity(false)
    // })
  } else {
    updatePlayerState("isLicense", false);
  }
};

export const sendGAEvents = (status, software, err) => {
  let action = status ? "Success" : "Error";
  if (err) {
    action += `|${err}`;
  }
  trackGa(action, {
    category: `${software}|EmailForm`,
    label: window.location.href,
    value: "",
    transport_type: "beacon",
  });
};

export const handleButtonEmailCollect = async (
  email,
  setEmail,
  setErrorEmail,
  setIsLoading,
  setIsSecondState,
  type,
  url,
  software,
  setIsShowText,
  isOperation
) => {
  const errorResult = checkValue("email", email);
  await setErrorEmail(errorResult);
  if (!errorResult) {
    await setIsLoading(true);
    setTimeout(
      () =>
        sendEventsCollectEmail(email, type, isOperation)
          .then((response) => {
            if (response.ok) {
              localStorage.setItem("emailCollect", true);
              localStorage.setItem("collectData", email);
              sendGAEvents(true, software);
              setIsSecondState(true);
              setIsShowText(true);
              window.open(url, targetStatus() ? "_blank" : "_self");
            } else {
              throw new Error(response.statusText);
            }
          })
          .catch((err) => {
            sendGAEvents(false, software, err.message);
            setErrorEmail("Something went wrong. Please try again.");
            setEmail("");
          })
          .finally(() => setIsLoading(false)),
      0
    );
  } else {
    sendGAEvents(
      false,
      software,
      email.length > 0 ? "Email Validation Failed" : "Empty Input"
    );
    setEmail("");
    setIsLoading(false);
  }
};

export const handleButtonEmailCollectTest = async (
  email,
  setEmail,
  setErrorEmail,
  setIsLoading,
  setIsSecondState,
  type,
  url,
  software,
  setIsShowText,
  setIsPaid,
  isCheckLicense = false,
  isOpacity,
  setIsOpacity
) => {
  const errorResult = checkValue("email", email);
  await setErrorEmail(errorResult);
  if (!errorResult) {
    await setIsLoading(true);
    requestHandler(
      email,
      setIsSecondState,
      type,
      software,
      setIsShowText,
      url,
      setErrorEmail,
      setEmail,
      setIsPaid,
      setIsLoading,
      isCheckLicense,
      isOpacity,
      setIsOpacity
    );
  } else {
    sendGAEvents(
      false,
      software,
      email.length > 0 ? "Email Validation Failed" : "Empty Input"
    );
    setEmail("");
    setIsLoading(false);
  }
};

const requestHandler = (
  email,
  setIsSecondState,
  type,
  software,
  setIsShowText,
  url,
  setErrorEmail,
  setEmail,
  setIsPaid,
  setIsLoading,
  isCheckLicense,
  isOpacity,
  setIsOpacity
) => {
  setTimeout(
    () =>
      sendEventsCollectEmail(email, type)
        .then((response) => {
          if (response.ok) {
            isCheckLicense && handleCheckLicenseForm(setIsPaid, setIsOpacity);
            localStorage.setItem("emailCollect", true);
            localStorage.setItem("collectData", email);
            sendGAEvents(true, software);
            setIsSecondState(true);
            setIsShowText(true);
            window.open(url, targetStatus() ? "_blank" : "_self");
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          sendGAEvents(false, software, err.message);
          setErrorEmail("Something went wrong. Please try again.");
          setEmail("");
        })
        .finally(() => {
          setIsLoading(false);
          setIsOpacity(false);
        }),
    0
  );
};

export const pasteErrorClasses = (isError1, isError2) => {
  const class1 = isError1 ? "input-field-error1" : "";
  const class2 = isError2 ? "input-field-error2" : "";
  return class1 && class2 ? "" : class1 || class2;
};

export const getTrueClasses = (...args) =>
  (args || []).filter(Boolean).join(" ");

// export const isSavedUserInfo = () => {
//   if (typeof window !== "undefined") {
//     const isEmailCollectLocalStorage =
//       localStorage.getItem("emailCollect") &&
//       localStorage.getItem("collectData");
//     return !!isEmailCollectLocalStorage || isAuthorizedUser();
//   }
// };
export const isSavedUserInfo = () => {
  if (typeof window === "undefined") return false;
  const emailCollect = localStorage.getItem("emailCollect");
  const collectData = localStorage.getItem("collectData");
  const match = document.cookie.match(/(^|; )h=([^;]*)/);
  const cookieHValue = match ? match[2] : null;
  return (!!emailCollect && !!collectData) || !!cookieHValue;
};

export const isAuthorizedUser = () => !!getCookie("h");

// export const getCookie = (name) => {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// };
export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^|; )${name}=([^;]*)`));
  return match ? match[2] : null;
};
// const r = () => "0123456789"[Math.floor(Math.random() * 10)];
const r = () =>
  window.checkATest
    ? "abcdefghijklmnopqrstuvwxyz0123456789"[Math.floor(Math.random() * 6)]
    : crypto.getRandomValues(new Uint32Array(1))[0] % 10;

const s = (v) => btoa(JSON.stringify(v));
const c = (v) => {
  console.log("getA: c");
  const k = "12";
  const o = {};
  o["ev" + "ent"] = "ch" + "eck";
  o[["d", "a", "t", "a"].join("")] = v ? { ["a"]: v } : { ["e"]: "12" };

  try {
    return s(o);
  } catch {
    o["da" + "t" + "a"] = { ["e"]: k };
    return s(o);
  }
};

export const getA = () =>
  (([_, v] = []) =>
    ((b = c(v)) =>
      b.length >= 3 ? b[0] + b[1] + b[2] + r() + b.slice(3) : r() + b)())(
    document.cookie.match(/h=([^;]*)/) || []
  );

export const setCookie = (cname, cvalue, exdays, isSecure, sameSiteValue) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  const secure = isSecure ? "secure;" : "";
  const sameSite = sameSiteValue ? `SameSite=${sameSiteValue};` : "";
  const cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;" + secure + sameSite;
  document.cookie = cookie.slice(0, cookie.length - 1);
};

export const detectBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1 ||
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera
  ) {
    return "Opera";
  } else if (navigator.userAgent.search(/YaBrowser/) > 0) {
    return "YaBrowser";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    return "IE"; // crap
  } else {
    return "Unknown";
  }
};

export const timeInSeconds = (str = "0:00", separator = ":") => {
  const arr = str.split(separator);
  const getTimeInSeconds = (str, multiplier) => parseInt(str, 10) * multiplier;
  return arr.length === 3
    ? Number(getTimeInSeconds(arr[0], 3600)) +
        Number(getTimeInSeconds(arr[1], 60)) +
        Number(arr[2])
    : Number(getTimeInSeconds(arr[0], 60)) + Number(arr[1]);
};

export class LinkElement {
  constructor(href, rel, type) {
    this.href = href;
    this.rel = rel;
    this.type = type;
  }

  getElement() {
    const element = document.createElement("link");
    element.href = this.href;
    element.rel = this.rel;
    element.type = this.type;
    return element;
  }
}

export const formatNumber = (languageCode, num) => {
  if (Math.floor(num) !== num) {
    return num.toLocaleString(languageCode, {
      minimumFractionDigits: 2,
    });
  }
  return num.toLocaleString(languageCode);
};
